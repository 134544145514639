<template>
  <div class="law-page">
    <r-container>
      <r-btn icon text @click.prevent="$router.push({name:'base'})">
      <r-icon v-html="$r.icons.arrow_left"></r-icon>
      </r-btn>
      <r-text-editor-preview class="mt-5" :text="law"></r-text-editor-preview>
    </r-container>
  </div>
</template>

<script>
export default {
  name: "law",
  data() {
    return {
      law: ""
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios.get("home/settings/multi/law").then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          this[data[i]["name"]] = data[i]["value"];
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
